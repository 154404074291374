// import React from "react";
// import { Link } from "gatsby";
// import { StaticImage } from "gatsby-plugin-image";
// import oxxo from "../../images/snickers-hunger-insurance.png";

// // import snickersHungerInsuranceLogo from "../../images/snickers-hunger-insurance-pt.png"

// const Logo = () => {
//   return (
//     <>
//       <div className="d-flex align-items-end">
//         <Link className="d-inline-block" to="/ar">
//           <figure className="mb-0">
//             <StaticImage
//               src="../../images/snickers-hunger-insurance.png"
//               alt="Snickers Logo"
//               quality={100}
//             />
//           </figure>
//         </Link>
//         <h4 className="text-secondary">EXCLUSIVO&nbsp;EN:</h4>
//         <img className="oxxo-logo" src={oxxo} alt="OXXO Logo" />
//       </div>
//     </>
//   );
// };

// export default Logo;

import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// import snickersHungerInsuranceLogo from "../../images/snickers-hunger-insurance.png"

const Logo = () => {
  return (
    <>
      <Link className="d-inline-block" to="/">
        <figure className="mb-0">
          <StaticImage
            src="../../images/snickershungerinsuranceAr.png"
            alt="Snickers Logo"
            quality={100}
          />
          {/* <img
            src={snickersHungerInsuranceLogo}
            alt="Snickers Logo"
            className="img-fluid"
          /> */}
        </figure>
      </Link>
    </>
  );
};

export default Logo;
